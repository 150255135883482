import React from 'react';
import styled from 'styled-components';
import { Section, SubSection, SubSectionHeader } from '../../../shared/Section';
import { Centralized } from '../../../shared/layout';
import { PageContent } from '../../../shared/Page';
import { TeamMemberList } from './TeamMemberList';

const StyledOurTeam = styled(Section)`
  padding-top: 1rem;
`;

const OurTeam = () => (
  <StyledOurTeam>
    <Centralized>
      <PageContent>
        <SubSection>
          <SubSectionHeader>Our team</SubSectionHeader>
          <TeamMemberList />
        </SubSection>
      </PageContent>
    </Centralized>
  </StyledOurTeam>
);

export { OurTeam };