import React from 'react';
import styled from 'styled-components';

import { Layout } from '../components/shared/layout';
import { Page } from '../components/shared/Page';
import { InvestorsBanner, InvestorsContent } from '../components/modules/InvestorsPage';
import { OurTeam } from '../components/modules/VisionPage/OurTeam';

const StyledInvestorsPage = styled(Layout)`
  text-align: center;
`;

const InvestorsPage = () => (
  <StyledInvestorsPage headerTransparent>
    <Page>
      <InvestorsBanner />
      <InvestorsContent />
      <OurTeam />
    </Page>
  </StyledInvestorsPage>
);

export default InvestorsPage;
