import React from 'react';
import styled from 'styled-components';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Banner, BannerContent, BannerDescription, BannerContentItem, BannerTitle,
  WatchVideoBannerButton,
} from '../../shared/Banner';
import { PageImage } from '../../shared/PageImage';
import InvestorsBannerIconSource from './images/InvestorsBannerIcon.png';
import { STYLES } from '../../../style';
import { showModal } from '../../../states/modal/actions';
import { MODAL_TYPE } from '../../shared/ModalManager/constants';
import { CONFIG } from '../../../config'

const StyledInvestorsBanner = styled(Banner)``;

const BannerImage = styled(PageImage)`
  max-height: 319px;
  cursor: pointer;
`;

const InvestorsBanner = ({
  dispatch,
}) => (
  <StyledInvestorsBanner color={STYLES.PAGE_INVESTORS_BANNER_COLOR}>
    <BannerContent>
      <BannerContentItem>
        <BannerImage
          src={InvestorsBannerIconSource}
          onClick={() => dispatch(
            showModal(
              MODAL_TYPE.YOUTUBE_MODAL,
              { videoId: CONFIG.INVESTOR_VIDEO_ID },
            ),
          )}
        />
      </BannerContentItem>
      <BannerContentItem>
        <BannerTitle>HOW YOU CAN MAKE A DIFFERENCE</BannerTitle>
        <BannerDescription>
          Hand Carry is always looking for partners and
          investors who we can work together with to change
          logistics for the better. Watch our video and say hello!
        </BannerDescription>
        <WatchVideoBannerButton
          videoId={CONFIG.INVESTOR_VIDEO_ID}
        />
      </BannerContentItem>
    </BannerContent>
  </StyledInvestorsBanner>
);

InvestorsBanner.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

const EnhancedInvestorsBanner = compose(
  connect(),
)(InvestorsBanner);

export { EnhancedInvestorsBanner as InvestorsBanner };