import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { TeamMemberImage } from './TeamMemberImage';
import { SCREEN_SIZE } from '../../../../../style';

const StyledTeamMemberListItem = styled.div`
  text-align: center;
  padding: 1.6rem 0;
  ${SCREEN_SIZE.MD} {
    flex: 0 0 50%;
    padding: 1rem 0;
  }
  ${SCREEN_SIZE.LG} {
    flex: 1;
    padding: .5rem;
  }
`;

const TeamMemberName = styled.div`
  font-size: 1.125rem;
  line-height: 1.625rem;
  font-weight: 600;
`;

const TeamMemberTitle = styled.div`
  font-size: 1.125rem;
  line-height: 1.625rem;
`;

const TeamMemberListItem = ({
  avatar,
  name,
  title,
}) => (
  <StyledTeamMemberListItem>
    <TeamMemberImage src={avatar} />
    <TeamMemberName>{name}</TeamMemberName>
    <TeamMemberTitle>{title}</TeamMemberTitle>
  </StyledTeamMemberListItem>
);

TeamMemberListItem.propTypes = {
  avatar: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export { TeamMemberListItem };