import React from 'react';
import styled from 'styled-components';
import { Centralized } from '../../shared/layout';
import { PageContent } from '../../shared/Page';
import { Section, SubSection, SubSectionHeader } from '../../shared/Section';
import { Paragraph } from '../../shared/Paragraph';

const StyledInvestorsContent = styled(Section)`
`;

const InvestorsContent = () => (
  <StyledInvestorsContent>
    <Centralized>
      <PageContent>
        <SubSection>
          <SubSectionHeader>Investors</SubSectionHeader>
          <Paragraph>
            At Hand Carry, we know that we cannot achieve our goals alone. We
            truly value the support our friends and family have given us. Hand
            Carry is actively seeking investors and partners that can help us
            accelerate our growth and deliver the best experience possible. We
            welcome, angels, venture capitalists, private equity as well as
            industry partners that may or may not include logistics, marketing and
            communications.
          </Paragraph>

          <Paragraph>
            We are also looking for talented individuals who believe in Hand
            Carry who would like to contribute their sweat and convert it to
            equity. We’re always looking for talented individuals to join our
            team who want to become part of something great.
          </Paragraph>

          <Paragraph>
            No chat bots or off shore secretaries, we’re real people and will
            answer your enquires immediately as we live and breathe Hand Carry
            every single day. We have people in Japan, Hong Kong, and Australia.
          </Paragraph>

          <Paragraph>
            We look forward to hearing from you.
          </Paragraph>
        </SubSection>
      </PageContent>
    </Centralized>
  </StyledInvestorsContent>
);

export { InvestorsContent };